<template>
  <b-modal
    id="update-autopay-modal"
    ref="updatePaymentMethodModal"
    size="lg"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    :title="modalTitle"
  >
    <p>You have selected "No Payment Method". Are you sure you want to alter the payment method in this manner? Making this adjustment may cause issues with your service if payments lapse.</p>
    <b-button-group class="float-right">
      <b-button
        variant="danger"
        class="mr-2"
        aria-label="cancel button"
        @click="cancelButtonHandler"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        aria-label="proceed button"
        @click="submitButtonHandler"
      >
        Proceed
      </b-button>
    </b-button-group>
  </b-modal>
</template>

<script>
  export default {
    name: 'UpdateAutoPayModal',

    props: {
      companyId: {
        type: String,
      },
    },

    data() {
      return {
        loaded: false,
      }
    },
    computed: {
      tableFields() {
        return [
          { key: 'company.name', label: 'Company' },
          { key: 'product.name', label: 'Service' },
          { key: 'action', label: 'Action' },
        ]
      },
      modalTitle() {
        return `Preparing to Update Payment Method used for AutoPay`
      },
    },

    methods: {
      hideModal() {
        this.$refs.updatePaymentMethodModal.hide()
      },

      cancelButtonHandler() {
        this.$emit('reject-autopay-warning')
        this.hideModal()
      },

      submitButtonHandler() {
        this.$emit('accept-autopay-warning')
        this.hideModal()
      },
    },
  }
</script>
