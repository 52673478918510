<template>
  <div>
    <h3>{{ company && company.name ? company.name : '' }}</h3>

    <b-button
      style="margin-bottom: 10px"
      variant="primary"
      aria-label="save button"
      @click="saveAndPresentUpdateAutoPayModal"
    >
      <feather-icon type="tag" />Save Changes
    </b-button>

    <b-button
      variant="link"
      class="ml-auto float-right"
      aria-label="add payment button"
      @click="presentAddPayment"
    >
      <i><feather-icon type="plus" /></i>
      Add payment method
    </b-button>

    <ajax-table ref="servicesTable" :table-definition="tableDefinition" @loaded="onLoad">
      <template v-slot:cell(description)="data">
        {{ data.item.product.description }}
      </template>
      <template v-slot:head(required)>
        <b-tooltip target="autopay_required_head" placement="right" offset="60">
          This field indicates whether or not AutoPay is required for the given service.
          By opting out of our AutoPayment option you will still be required to pay via regular
          invoice whenever the service becomes due. If your intention is to cancel the service,
          please be sure to cancel the service through the "Services" tab
        </b-tooltip>
        <span id="autopay_required_head" style="border-bottom: 1px dashed">
          AutoPay Required
        </span>
      </template>
      <template v-slot:cell(required)="data">
        {{
          isAutopayRequired(data.item) ? 'Required' : 'Not Required'
        }}
      </template>
      <template v-if="data.item" v-slot:cell(card)="data">
        <div>
          <b-select
            v-model="data.item.autopay_payable_id"
            :options="paymentOptions"
            :disabled="data.item.status !== 'active'"
            @input="dirtyRecord($event, data.item.id)"
          />
        </div>
      </template>
    </ajax-table>

    <payment-method-modal
      @badCardIssuer="setBadCardIssuer"
      @update="addPaymentMethodDidUpdate"
    />
    <update-auto-pay-modal :rows="rows" :company-id="companyId" @update="hideModal('update-autopay-modal')" />
  </div>
</template>

<script>
import { paymentMethodsMixin } from '@/mixins/paymentMethodsMixin'
import { mapGetters } from 'vuex'
import FeatherIcon from './shared/FeatherIcon'
import PaymentMethodModal from './PaymentMethodModal'
import AjaxTable from './shared/AjaxTable'
import UpdateAutoPayModal from '@/components/UpdateAutoPayModal'
import axiosClient from '../http'

export default {
  name: "CompanyAutopaymentsBody",
  components: {
    FeatherIcon,
    PaymentMethodModal,
    AjaxTable,
    UpdateAutoPayModal,
  },
  mixins: [paymentMethodsMixin],
  props: {
    companyId: {
      type: String,
      default: null,
      required: true,
    },
    limitOverride: {
      type: Number,
      default: null,
      required: false,
    },
    query: {
      type: String,
      default: null,
      required: false,
    },
    appliedFilters: {
      type: Array,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      company: null,
      rows: [],
    }
  },
  computed: {
    ...mapGetters({
      token: 'session/getToken',
    }),
    tableDefinition() {
      return {
        columns: [
          { key: 'description', label: 'Description' },
          { key: 'required', label: 'Required' },
          { key: 'card', label: 'Card' },
        ],
        url: `client/services?company_id=${this.companyId}&status=active`,
        parameters: {
          search_name: 'search_company_autopay_page',
          query: this.query,
          filter: this.appliedFilters,
        },
        defaultOrderDirection: 'desc',
        defaultOrderBy: 'created_at',
        limitOverride: this.limitOverride,
      }
    },
    paymentOptions() {
      let paymentOptions = []
      // Add each card to the list.
      if (this.cards.length > 0) {
        paymentOptions.push(...this.cards.map(card => {
          return { value: card, text: card.last4, paymentOptionType: 'card' }
        }))
      }

      // Add each ach to the list.
      if (this.automatedClearingHouses.length > 0) {
        paymentOptions.push(...this.automatedClearingHouses.map(ach => {
          return { value: ach, text: ach.bank_account_number, paymentOptionType: 'automatedClearingHouse' }
        }))
      }
      return paymentOptions
    },
  },
  async mounted() {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    }

    this.company = (await (await fetch(
      `/api/v3/client/companies/${this.companyId}`,
      {
        headers,
        method: 'GET',
      }
    )).json()).result
  },
  methods: {
    onLoad(rows) {
      this.rows = rows
    },
    async hideModal(modalId) {
      this.$bvModal.hide(modalId)
    },
    presentAddPayment() {
      this.$bvModal.show('payment-method-modal')
    },
    saveAndPresentUpdateAutoPayModal() {
      if (this.rows.some(service => service.autopay_payable_id === null)) {
        if (this.rows.some(service => service.isDirty !== undefined)){
          this.$bvModal.show('update-autopay-modal')
        }
      } else {
        if (this.rows.some(service => service.isDirty !== undefined)) {
          const updates = this.rows.filter(service => service.isDirty !== undefined)
          updates.forEach(service => {
            this.submit(service)
          })
          this.$emit('update', true)
        }
      }
    },
    async addPaymentMethodDidUpdate(payableId, errors) {
      this.$refs.servicesTable.reload()
      this.$bvModal.hide('payment-method-modal')
      this.displayResultToast(payableId, false, errors)
    },
    dirtyRecord(event, recordId) {
      const index = this.rows.findIndex(record => record.id === recordId)
      this.rows[index].isDirty = event
    },
    isAutopayRequired(service) {
      return (global._.get(service, 'product.autopayment_required', false))
    },
    async submit(service) {
      await axiosClient.post(
        `client/companies/${this.companyId}/services/${service.id}/set_autopay_id`,
        service
      )
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
