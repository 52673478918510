<template>
  <b-container>
    <masthead
      title="Manage Auto Pay"
      @changed="searchChanged"
    />
    <company-autopayments-body
      :company-id="companyId"
      :query="query"
      :applied-filters="appliedFilters"
    />
  </b-container>
</template>

<script>
import Masthead from '@/components/shared/Masthead'
import CompanyAutopaymentsBody from '../components/CompanyAutopaymentsBody'

export default {
  name: 'CompanyAutopayments',
  components: {
    CompanyAutopaymentsBody,
    Masthead,
  },
  data() {
    return {
      query: null,
      appliedFilters: [],
    }
  },
  computed: {
    companyId() {
      return this.$route.params['companyId']
    },
  },
  methods: {
    searchChanged(searchObject) {
      this.query = searchObject.query
      this.appliedFilters = searchObject.filters
    },
  },
}
</script>
