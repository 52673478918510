import { humanize } from './strings'
const { DateTime } = require('luxon')

export const getDateFilter = (key) => {
  let obj = {}
  obj.key = key
  // Check the all upcoming or all past options
  switch (key) {
    case 'UPCOMING_ALL':
      obj.startTime = DateTime.local().ts / 1000
      obj.label     = 'All Upcoming'
      break
    case 'PAST_ALL':
      obj.endTime = DateTime.local().ts / 1000
      obj.label   = 'All Past'
      break
    case 'PAST_1_MONTH':
    case 'PAST_3_MONTHS':
    case 'PAST_6_MONTHS': {
        const months = key.split("_")[1]

        obj.key   = key
        obj.label = humanize(key.toLowerCase())

        const endTime = DateTime.local()
        const startTime = endTime.minus({ months: months })

        obj.startTime = Math.floor(startTime.ts / 1000)
        obj.endTime = Math.floor(endTime.ts / 1000)

    } break
    default: {
      let values = key.split("_")
      let offset = {}
      const tense      = values[0]
      const amount     = values.length > 2 ? parseInt(values[1]) : 1
      const duration   = values.length > 2 ? values[2] : values[1] + 's'
      offset[duration] = amount
      obj.key   = key
      obj.label = humanize(key.toLowerCase())
      obj.startTime = tense === 'UPCOMING' ? DateTime.local().ts / 1000 : DateTime.local().minus(offset).ts / 1000
      obj.endTime   = tense === 'UPCOMING' ? DateTime.local().plus(offset).ts / 1000 : DateTime.local().ts / 1000
    } break
  }
  return obj
}
