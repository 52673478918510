var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [
        _vm._v(_vm._s(_vm.company && _vm.company.name ? _vm.company.name : "")),
      ]),
      _c(
        "b-button",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { variant: "primary", "aria-label": "save button" },
          on: { click: _vm.saveAndPresentUpdateAutoPayModal },
        },
        [
          _c("feather-icon", { attrs: { type: "tag" } }),
          _vm._v("Save Changes\n  "),
        ],
        1
      ),
      _c(
        "b-button",
        {
          staticClass: "ml-auto float-right",
          attrs: { variant: "link", "aria-label": "add payment button" },
          on: { click: _vm.presentAddPayment },
        },
        [
          _c("i", [_c("feather-icon", { attrs: { type: "plus" } })], 1),
          _vm._v("\n    Add payment method\n  "),
        ]
      ),
      _c("ajax-table", {
        ref: "servicesTable",
        attrs: { "table-definition": _vm.tableDefinition },
        on: { loaded: _vm.onLoad },
        scopedSlots: _vm._u(
          [
            {
              key: "cell(description)",
              fn: function (data) {
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(data.item.product.description) +
                      "\n    "
                  ),
                ]
              },
            },
            {
              key: "head(required)",
              fn: function () {
                return [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: "autopay_required_head",
                        placement: "right",
                        offset: "60",
                      },
                    },
                    [
                      _vm._v(
                        '\n        This field indicates whether or not AutoPay is required for the given service.\n        By opting out of our AutoPayment option you will still be required to pay via regular\n        invoice whenever the service becomes due. If your intention is to cancel the service,\n        please be sure to cancel the service through the "Services" tab\n      '
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: { "border-bottom": "1px dashed" },
                      attrs: { id: "autopay_required_head" },
                    },
                    [_vm._v("\n        AutoPay Required\n      ")]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(required)",
              fn: function (data) {
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.isAutopayRequired(data.item)
                          ? "Required"
                          : "Not Required"
                      ) +
                      "\n    "
                  ),
                ]
              },
            },
            _vm.data.item
              ? {
                  key: "cell(card)",
                  fn: function (data) {
                    return [
                      _c(
                        "div",
                        [
                          _c("b-select", {
                            attrs: {
                              options: _vm.paymentOptions,
                              disabled: data.item.status !== "active",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.dirtyRecord($event, data.item.id)
                              },
                            },
                            model: {
                              value: data.item.autopay_payable_id,
                              callback: function ($$v) {
                                _vm.$set(data.item, "autopay_payable_id", $$v)
                              },
                              expression: "data.item.autopay_payable_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("payment-method-modal", {
        on: {
          badCardIssuer: _vm.setBadCardIssuer,
          update: _vm.addPaymentMethodDidUpdate,
        },
      }),
      _c("update-auto-pay-modal", {
        attrs: { rows: _vm.rows, "company-id": _vm.companyId },
        on: {
          update: function ($event) {
            return _vm.hideModal("update-autopay-modal")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }