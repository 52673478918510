var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "updatePaymentMethodModal",
      attrs: {
        id: "update-autopay-modal",
        size: "lg",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
        title: _vm.modalTitle,
      },
    },
    [
      _c("p", [
        _vm._v(
          'You have selected "No Payment Method". Are you sure you want to alter the payment method in this manner? Making this adjustment may cause issues with your service if payments lapse.'
        ),
      ]),
      _c(
        "b-button-group",
        { staticClass: "float-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "mr-2",
              attrs: { variant: "danger", "aria-label": "cancel button" },
              on: { click: _vm.cancelButtonHandler },
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "primary", "aria-label": "proceed button" },
              on: { click: _vm.submitButtonHandler },
            },
            [_vm._v("\n      Proceed\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }