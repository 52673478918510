var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c("masthead", {
        attrs: { title: "Manage Auto Pay" },
        on: { changed: _vm.searchChanged },
      }),
      _c("company-autopayments-body", {
        attrs: {
          "company-id": _vm.companyId,
          query: _vm.query,
          "applied-filters": _vm.appliedFilters,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }